const debounce = (fn: (...args: any[]) => any, delay: number): ((...args: any[]) => any) => {
  let timeoutId: number;
  return function (...args: any) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export default debounce;
