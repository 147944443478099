import {AxiosResponse} from 'axios';
import ApiService from '@/services/_helper/api.service';
import ContentInterface from '@/types/api/ContentInterface';

export default class ContentService {
  static getContent(params: {pageNumber: number; itemsCount: number; key?: string}): Promise<AxiosResponse> {
    return ApiService.get('/frontend-content/search', {params});
  }
  static addContent(content: ContentInterface): Promise<AxiosResponse> {
    return ApiService.post('/frontend-content', content);
  }
  static updateContent(content: ContentInterface): Promise<AxiosResponse> {
    return ApiService.put(`/frontend-content/${content.id}`, content);
  }
  static deleteContent(id: number): Promise<AxiosResponse> {
    return ApiService.delete(`/frontend-content/${id}`);
  }
}
