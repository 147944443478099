export default () => {
  const contentValidationRules = {
    key: [{required: true, message: 'Type is required', trigger: 'blur'}],
    value: [
      {required: true, message: 'URL is required', trigger: 'blur'},
      {type: 'url', message: 'Valid url is required', trigger: 'blur'},
    ],
  };

  return {contentValidationRules};
};
